<template>
    <div class="slidecontainer">
        <input
            id="myRange"
            v-model="newValue"
            type="range"
            min="1"
            max="200"
            class="slider"
            @change="sendUpdate"/>
    </div>
</template>

<script>
export default {
    name: "Slider",
    props: {
        value: {type: Number, required: false, default: 100}
    },
    emits: ['input'],
    data() {
        return {
            newValue: this.value
        }
    },
    methods: {
        sendUpdate() {
            this.$emit("input", this.newValue)
        }
    }
};
</script>

<style>
.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
}
</style>
