const { collection } = require("firebase/firestore")

async function WriteCommonWords() {
    const publicCollection = collection("public")
    const resourcesDocRef = publicCollection.doc("resources");
    const wordsCollection = resourcesDocRef.collection("commonWords");

    const commonWords = require("../../public/common_words.json");

    commonWords.forEach(word => {
        wordsCollection.doc(word.word).set(word);
    });
}

async function WriteOriginalKit() {
    const words = require("../../public/original_kit.json").map(w => w.word);
    resourcesDocRef.set({ originalKit: words });
}

async function GetCommonWords() {
    const commonWords = require("../../public/common_words.json");
    return commonWords

    // const words = await firebase.GetCollectionData(wordsCollection)
    // return Object.values(words)
}

async function GetOriginalKit() {
    const originalKit = require("../../public/original_kit.json").map(w => w.word);
    // const resourceDoc = await resourcesDocRef.get()
    // const resources = resourceDoc.data()
    // const originalKit = resources.originalKit

    // join with pos and rank
    const commonWords = await GetCommonWords()
    const matches = commonWords.filter(w => originalKit.includes(w.word.toLowerCase()))
    return originalKit.map(w => {return matches.find(m => m.word.toLowerCase() == w)})
}

module.exports = { WriteOriginalKit, WriteCommonWords, GetCommonWords, GetOriginalKit };
