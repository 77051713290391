<template>
    <div class="vue-drag-select" @mousedown="onMouseDown">
        <slot :selectedItems="selectedItems" />
        <div
            v-if="mouseDown"
            ref="childItem"
            class="vue-drag-select-box"
            :style="selectionBoxStyling"></div>
    </div>
</template>

<script>

export default {
    name: "VueDragSelect",
    props: {
        selectorClass: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: "rgba(0, 162, 255, .4)"
        },
        wordIsMoving: {
            type: Boolean,
            default: false
        }
    },
emits: ['change', 'SelectRectangle'],
    data() {
        return {
            mouseDown: false,
            concat: false,
            startPoint: null,
            endPoint: null,
            selectedItems: [],
            keepSelectionOverride: false
        };
    },
    computed: {
        selectionBox() {
            // Only set styling when necessary
            if (!this.mouseDown || !this.startPoint || !this.endPoint || this.wordIsMoving)
                return {};

            const clientRect = this.$el.getBoundingClientRect();
            const scroll = this.getScroll();

            // Calculate position and dimensions of the selection box
            const left =
                Math.min(this.startPoint.x, this.endPoint.x) -
                clientRect.left -
                scroll.x;
            const top =
                Math.min(this.startPoint.y, this.endPoint.y) -
                clientRect.top -
                scroll.y;
            const width = Math.abs(this.startPoint.x - this.endPoint.x);
            const height = Math.abs(this.startPoint.y - this.endPoint.y);

            // Return the styles to be applied
            return {
                left,
                top,
                width,
                height
            };
        },
        selectionBoxStyling() {
            // Only set styling when necessary
            if (!this.mouseDown || !this.startPoint || !this.endPoint || this.wordIsMoving) {
                return { background: this.color };
            }
            const { left, top, width, height } = this.selectionBox;

            // Return the styles to be applied
            return {
                background: this.color,
                left: `${left}px`,
                top: `${top}px`,
                width: `${width}px`,
                height: `${height}px`
            };
        },
        childItems() {
            return this.$refs.childItem || []
        }
    },
    watch: {
        wordIsMoving(val) {
            if (val) {
                this.keepSelectionOverride = true
            }
        },
    },
    beforeUnmount() {
        // Remove event listeners
        window.removeEventListener("mousemove", this.onMouseMove);
        window.removeEventListener("mouseup", this.onMouseUp);

        this.childItems.forEach(child => {
            child.$off("click");
        });
    },
    methods: {
        getScroll() {
            // If we're on the server, default to 0,0
            if (typeof document === "undefined") {
                return {
                    x: 0,
                    y: 0
                };
            }

            return {
                x:
                    this.$el.scrollLeft ||
                    document.body.scrollLeft ||
                    document.documentElement.scrollLeft,
                y:
                    this.$el.scrollTop ||
                    document.body.scrollTop ||
                    document.documentElement.scrollTop
            };
        },
        onMouseDown(event) {
            // Ignore right clicks
            if (event.button === 2) return;

            // Check if shift is down
            this.concat = event.shiftKey;

            // Register begin point
            this.mouseDown = true;
            this.startPoint = {
                x: event.pageX,
                y: event.pageY
            };

            // Start listening for mouse move and up events
            window.addEventListener("mousemove", this.onMouseMove);
            window.addEventListener("mouseup", this.onMouseUp);
        },
        onMouseMove(event) {
            // Update the end point position
            if (this.mouseDown && !this.wordIsMoving) {
                this.endPoint = {
                    x: event.pageX,
                    y: event.pageY
                };
            }
        },
        onMouseUp(event) {
            // Clean up event listeners
            window.removeEventListener("mousemove", this.onMouseMove);
            window.removeEventListener("mouseup", this.onMouseUp);

            // only do the update if we weren't just moving
            if (this.keepSelectionOverride)
            {
                // reset the override flag - this gets set whenever wordIsMoving becomes true
                this.keepSelectionOverride = false
            } else {
                console.log("onMouseUp emit SelectRectangle")
                this.$emit("SelectRectangle", this.selectionBox)
            }

            // Reset state
            this.mouseDown = false;
            this.concat = false;
            this.startPoint = null;
            this.endPoint = null;
        },
        isItemSelected(el) {
            if (el.classList.contains(this.selectorClass)) {
                const boxA = this.selectionBox;
                const boxB = {
                    top: el.offsetTop,
                    left: el.offsetLeft,
                    width: el.clientWidth,
                    height: el.clientHeight
                };

                return !!(
                    boxA.left <= boxB.left + boxB.width &&
                    boxA.left + boxA.width >= boxB.left &&
                    boxA.top <= boxB.top + boxB.height &&
                    boxA.top + boxA.height >= boxB.top
                );
            }

            return false;
        }
    }
};
</script>

<style>
.vue-drag-select {
    position: relative;
    user-select: none;
}

.vue-drag-select-box {
    position: absolute;
    z-index: 99;
}
</style>
