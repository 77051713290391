import { createApp } from "vue";
import VueGtag from "vue-gtag-next";
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'

import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
    .use(store)
    .use(router)
    .use(ContextMenu)
    .use(VueGtag, {
        property: {
            id: "G-MLXRZ33BVD"
        }
    })
    .mount("#app");

store.dispatch("common/loadThemes");
const firebase = require("../functions/firebase");

const app = firebase.app;

import { getAuth, onAuthStateChanged } from "firebase/auth";

async function afterLoginTask(authUser) {
    let user = {
        uid: authUser.uid,
        name: authUser.displayName,
        email: authUser.email,
        avatar: authUser.photoURL
    };
    store.commit("user/setUser", user);
    await store.dispatch("user/loadUserData");
}

const auth = getAuth(app);
onAuthStateChanged(auth, user => {
    if (user) {
        afterLoginTask(user);
    } else {
        store.commit("user/setUser", {});
        store.commit("user/setSessionIds", []);
    }
});
