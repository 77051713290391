<template>
    <div>
        <Fridge />
    </div>
</template>

<script>
import Fridge from "@/components/Fridge";

export default {
    name: "Home",
    components: { Fridge }
};
</script>

<style lang="scss" scoped></style>
