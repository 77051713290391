import { createStore } from "vuex";
import common from "./common";
import session from "./session";
import user from "./user";


export default createStore({
    modules: {
        common: common,
        user: user,
        session: session
    }
});
