<template>
    <div id="example-1">
        <Burger v-model="show" :initial-value="show" @click="show = !show"/>
        <transition name="slide">
            <aside v-show="show">
                *** SIDEBAR ***
                <FridgeCanvas />
            </aside>
        </transition>
    </div>
</template>
<script>
import Burger from "./Burger.vue"
import FridgeCanvas from './FridgeCanvas.vue';

export default
  {
    name: "Sidebar",
    components: { Burger, FridgeCanvas },
    data() {
        return {
            show: false
        }
    }
};
</script>
<style>
.slide-enter-active {
	transition: all .7s ease;
}

.slide-leave-active {
	transition: all .7s ease;
}

.slide-enter, .slide-leave-active {
	transform: translateX(100%);
}

aside {
	width: 33%;
	height: 100%;
	background: repeating-linear-gradient(-45deg, rgba(204,204,204, 0.15), rgba(204,204,204, 0.15) 1px, rgba(85,85,85, 0.2) 1px, rgba(85,85,85, 0.1) 7px);
	color: white;
	position: fixed;
	top: 0;
	right: 0;
}
</style>
