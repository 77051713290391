const firebaseConfig = {
    apiKey: "AIzaSyDB-Og8BxFu-rN1Ay7gVaTwNbllDdBTWLo",
    authDomain: "magnetic-poetic.firebaseapp.com",
    projectId: "magnetic-poetic",
    storageBucket: "magnetic-poetic.appspot.com",
    ocrInputBucket: "magnetic-poetic-ocr-input",
    messagingSenderId: "63875030856",
    appId: "1:63875030856:web:db6ad7f704f5f046211d7d",
    measurementId: "G-MLXRZ33BVD"
};


const { initializeApp } = require("firebase/app")
const app = initializeApp(firebaseConfig);

const { getFirestore }  = require( "firebase/firestore");
const db = getFirestore(app);

const { getAuth }  = require( "firebase/auth");
const auth = getAuth(app);

const { getDatabase }  = require( "firebase/database");
const rtdb = getDatabase();

async function CallFirebaseFunction(functionName, text) {
    const { getFunctions, httpsCallable } = require("firebase/functions");

    try {
        const functions = getFunctions();
        const fn = httpsCallable(functions, functionName);
        const result = await fn({ text })
        const data = result.data;
        console.log({data})
    } catch (error) {
        console.error(`firebase function ${functionName} error`, {error})
    }
}

module.exports = {
    firebaseConfig,
    db,
    auth,
    app,
    rtdb,
    CallFirebaseFunction
};
