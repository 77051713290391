<template>
    <div class="columns is-mobile">
        <DragSelect
            id="FridgeCanvas"
            ref="FridgeCanvas"
            selector-class="item"
            :word-is-moving="Boolean(movingWordId)"
            @SelectRectangle="SelectRectangle">
            <Word
                v-for="word in words"
                :id="word.id"
                :key="word.id"
                :text="word.text"
                :x="word.x"
                :y="word.y"
                :is-selected="selectedWordIds.includes(word.id)"
                @created="SetWordSize"
                @moved="UpdateWordPosition"
                @toggleSelected="ToggleSelectedWord"
                @moving="SetWordIsMoving"
                @dragOthers="MoveOtherWords"
                @deleteMe="DeleteSelected"
                @analyzeSentiment="AnalyzeSentiment"
                @analyzeSyntax="AnalyzeSyntax"/>
        </DragSelect>
    </div>
</template>

<script>
import { CallFirebaseFunction } from "../../functions/firebase";
import Word from "@/components/Word";
import DragSelect from "./DragSelect.vue";

export default {
    name: "FridgeCanvas",
    components: { Word, DragSelect },
    data() {
        return {
            selectedWordIds: [],
            inputWordText: "",
            sessionId: "default",
            words: [],
            wordSizes: {},
            movingWordId: undefined,
            movedWordId: undefined
        };
    },
    computed: {
        selectedWords() {
            return this.words.filter(w => this.selectedWordIds.includes(w.id));
        },
        selectedWordsText() {
            const sortedWords = this.sortWords(this.selectedWords, [])
            return sortedWords.join(".  ")
        },
        sessionWords() {
            return this.$store.state.session.words;
        },
        sessionWordCount() {
            return this.sessionWords.length;
        },
        poem() {
            const sortedWords = this.sortWords(this.words, [])
            return sortedWords.join("\n")
        },
    },
    watch: {
        sessionWordCount() {
            this.SyncronizeWords();
        },
        sessionWords() {
            if (this.sessionWordCount > 0) {
                this.words = this.sessionWords;
            }
        },
        movingWordId() {
            console.log("movingWordId changed", this.movingWordId)
        },
    },
    mounted() {
        this.$store.dispatch("common/loadCommonWords");
        // this.$store.dispatch("common/loadOriginalKit");
        this.SyncronizeDimensions();
        this.SyncronizeWords();
    },
    methods: {
        MakeNewWord() {
            const word = {
                id: this.words.length + 1,
                text: this.inputWordText,
                x: 1,
                y: 1
            };
            this.words.push(word);
        },
        SetWordIsMoving(wordId) {
            this.movingWordId = wordId;
        },
        MoveOtherWords(position) {
            const otherWordIds = this.selectedWordIds.filter(
                id => id !== this.movingWordId
            );
            if (otherWordIds) {
                otherWordIds.forEach(id => {
                    const otherWord = this.words.find(w => w.id == id);
                    otherWord.x -= position.x;
                    otherWord.y -= position.y;
                });
            }
        },
        SetWordSize({ id, height, width }) {
            this.wordSizes[id] = { height, width };
        },
        UpdateWordPosition(word) {
            const selectedWord = this.words.find(w => w.id == word.id);
            selectedWord.x = word.left;
            selectedWord.y = word.top;

            this.movingWordId = undefined;
            this.movedWordId = word.id
            this.$store.dispatch("session/saveWord", selectedWord);
        },
        ToggleSelectedWord(word) {
            // bypass if the word is the same one as the one that's just stopped moving
            if (this.movingWordId == word.id) {
                console.log("movingWordId", this.movingWordId)
                this.movingWordId = undefined
                return
            }
            if (this.movedWordId == word.id) {
                console.log("movedWordId")
                this.movedWordId = undefined
                return
            }

            console.log("ToggleSelectedWord", {text: word.text, id: word.id, movingWordId: this.movingWordId})
            if (this.selectedWordIds.includes(word.id)) {
                this.selectedWordIds = this.selectedWordIds.filter(
                    id => id !== word.id
                );
            } else {
                this.selectedWordIds.push(word.id);
            }
        },
        SyncronizeWords() {
            this.words = this.$store.state.session.words;
        },
        SyncronizeDimensions() {
            const canvasRef = this.$refs.FridgeCanvas;
            if (canvasRef) {
                const canvasDiv = canvasRef.$el;
                if (canvasDiv) {
                    const height = canvasDiv.clientHeight;
                    const width = canvasDiv.clientWidth;
                    if (height > 0 && width > 0)
                    {
                        this.$store.dispatch("session/saveDimensions", {height, width});
                    }
                }
            }
        },
        SelectRectangle(selectionBox) {
            // bypass if the dragging is to move a word
            if (this.movingWordId) return

            const { height, left, top, width } = selectionBox;
            const right = left + width;
            const bottom = top + height;
            this.words.forEach(word => {
                word.left = word.x;
                word.top = word.y;
                word.height = this.wordSizes[word.id].height;
                word.width = this.wordSizes[word.id].width;
                word.right = word.left + word.width;
                word.bottom = word.top + word.height;
            });

            const selectedWords = this.words.filter(w => {
                return (
                    (w.left >= left &&
                        w.left <= right &&
                        w.top >= top &&
                        w.top <= bottom) ||
                    (w.left >= left &&
                        w.left <= right &&
                        w.bottom >= top &&
                        w.bottom <= bottom) ||
                    (w.right >= left &&
                        w.right <= right &&
                        w.top >= top &&
                        w.top <= bottom) ||
                    (w.right >= left &&
                        w.right <= right &&
                        w.bottom >= top &&
                        w.bottom <= bottom)
                );
            });
            console.log("SelectRectangle", selectedWords.map(w => w.text))
            this.selectedWordIds = selectedWords.map(w => parseInt(w.id));
        },
        DeleteSelected(wordId) {
            if (this.selectedWordIds.length > 0) {
                this.selectedWordIds.forEach(id => {
                    this.$store.dispatch("session/deleteWord", id);
                })
                this.selectedWordIds = [];
            } else {
                this.$store.dispatch("session/deleteWord", wordId);
            }
            this.movingWordId = undefined;
        },
        async AnalyzeSyntax() {
            await CallFirebaseFunction("analyzeSyntax", this.selectedWordsText)
        },
        async AnalyzeSentiment() {
            await CallFirebaseFunction("analyzeSentiment", this.selectedWordsText)
        },
        sortWords(words, sortedWords) {
            // get the top-most word
            const minY = Math.min(...words.map(w => w.y));
            const topWord = words.find(w => w.y == minY);
            if (topWord == undefined) return sortedWords

            // get any words with a top that's higher than the bottom of our top word
            // order them by their x position, then join with a space separator
            const bottomY = topWord.bottom;
            const rowWords = words.filter(w => w.y <= bottomY).sort((a, b) => {return a.x - b.x});
            const rowWordText = rowWords.map(w => w.text).join(" ");
            sortedWords.push(rowWordText)

            // pass all remaining words thru recursively until all are processed
            const rowWordIds = rowWords.map(w => w.id);
            const remainingWords = words.filter(w => !rowWordIds.includes(w.id))
            if (remainingWords) {
                return this.sortWords(remainingWords, sortedWords)
            }
            return sortedWords
        }
    }
};
</script>

<style scoped>
#FridgeCanvas {
    position: relative;
    height: 100vh;
}
html {
    height: 100%;
    overflow: hidden;
}

body {
    border: 0;
    margin: 0;
    padding: 0;
    font-family: "Lato";
    height: 100%;
    background: rgb(101, 31, 87);
    background: linear-gradient(
        45deg,
        rgba(101, 31, 87, 1) 0%,
        rgba(225, 113, 87, 1) 48%,
        rgba(249, 248, 113, 1) 100%
    );
}

.logo {
    align-self: center;
    color: #fff;
    font-weight: bold;
    font-family: "Lato";
}

.main-nav {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.8rem;
}

ul.sidebar-panel-nav {
    list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    display: block;
    padding-bottom: 0.5em;
}
</style>
