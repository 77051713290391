<template>
    <img id="profile-pic" :src="avatar" @click="loginLogout" />
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
const noPicUrl = require("../assets/no-pic.png");

export default {
    name: "LoginButton",
    data() {
        return {};
    },
    computed: {
        user() {
            return this.$store?.state?.user?.user || {};
        },
        loggedIn() {
            return this.user.uid !== undefined;
        },
        loginText() {
            return this.loggedIn ? "Logout" : "Login";
        },
        avatar() {
            return this.user.avatar || noPicUrl;
        }
    },
    methods: {
        async login() {
            try {
                const provider = new GoogleAuthProvider();
                const auth = getAuth();
                const result = await signInWithPopup(auth, provider);
                const credential = GoogleAuthProvider.credentialFromResult(
                    result
                );
                // const token = credential.accessToken;
                // const user = result.user;
                // console.log({token, user})
            } catch (error) {
                const email = error.email;
                const credential = GoogleAuthProvider.credentialFromError(
                    error
                );

                // const errorCode = error.code;
                // const errorMessage = error.message;
                console.error(`${errorCode} - ${errorMessage}`);
                // console.error({email, credential})
            }
        },
        async logout() {
            await this.$store.dispatch("user/signOut");
        },
        loginLogout() {
            if (this.loggedIn) {
                this.logout();
            } else {
                this.login();
            }
        }
    }
};
</script>

<style>
.logout-button {
    width: 50%;
    margin: 0 auto;
    cursor: pointer;
}
#profile-pic {
    display: inline-block;
    cursor: pointer;

    width: 50px;
    height: 50px;
    border-radius: 50%;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
</style>
