<template>
    <NavBar />
    <div>
        <Toast :text="toastMessage" :time="1000" @timesUp="toastMessage=''"/>
        <form>
            <div class="form-group">
                <button
                    v-show="loggedIn"
                    type="button"
                    class="btn btn-primary mx-2 mt-3"
                    @click="shuffle">
                    Shuffle
                </button>
                <button
                    v-show="loggedIn"
                    type="button"
                    class="btn btn-secondary mx-2 mt-3"
                    @click="getRandomWords">
                    Get Random Words
                </button>
                <button
                    v-show="loggedIn"
                    type="button"
                    class="btn btn-primary mx-2 mt-3"
                    @click="newSession">
                    new
                </button>
                <button
                    v-show="loggedIn"
                    type="button"
                    class="btn btn-secondary mx-2 mt-3"
                    @click="saveState">
                    save
                </button>
                <button
                    v-show="loggedIn"
                    type="button"
                    class="btn btn-primary mx-2 mt-3"
                    @click="deleteSession">
                    delete
                </button>
            </div>
        </form>
        <div ref="InputDiv" class="columns is-mobile">
            <div class="column is-half is-offset-one-quarter">
                <div class="field">
                    <label>
                        {{ spacing }}
                    </label>
                    <Slider v-model="spacing" @input="updateSpacing"/>
                </div>
                <div class="field">
                    <label class="label">
                        Words
                    </label>
                    <div class="control">
                        <input
                            v-model="inputWordText"
                            class="input is-focused"
                            type="text"
                            placeholder="Add more words:"
                            @keyup.enter="makeNewWord"/>
                    </div>
                </div>
            </div>
        </div>
        <sidebar />
        <FridgeCanvas />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FridgeCanvas from "./FridgeCanvas";
import NavBar from "./NavBar";
import Sidebar from './Sidebar.vue';
import Slider from './Slider.vue';
import Toast from "@/components/Toast";

export default {
    name: "Fridge",
    components: { NavBar, FridgeCanvas, Sidebar, Slider, Toast },
    data() {
        return {
            inputWordText: "",
            spacing: 100,
            toastMessage: ""
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn", "sessionId"])
    },
    watch: {
        sessionId() {
            if (this.sessionId) {
                this.$store.dispatch("session/loadState", this.sessionId);
            }
        }
    },
    methods: {
        updateSpacing(newValue) {
            this.$store.dispatch("session/adjustSpacing", {oldValue: this.spacing, newValue})
            this.spacing = newValue
        },
        makeNewWord() {
            const newWord = this.$store.dispatch(
                "session/makeNewWord",
                this.inputWordText
            );
            this.inputWordText = "";
        },
        async shuffle() {
            await this.$store.dispatch("session/shuffleWords");
        },
        async newSession() {
            const sessionId = await this.$store.dispatch("session/newSession");
            await this.$store.commit("user/addSession", {id: sessionId, words: []});
            await this.$store.commit("user/setSession", sessionId);
        },
        async getRandomWords() {
            await this.$store.dispatch("session/getRandomWords")
        },
        async triggerToast(msg) {
            this.toastMessage = msg
        },
        async saveState() {
            await this.$store.dispatch("session/saveState");
            this.triggerToast("save succeeded")
        },
        async deleteSession() {
            await this.$store.dispatch("user/deleteSession", {id: this.sessionId});
            await this.$store.dispatch("session/deleteSession", {id: this.sessionId});
            this.triggerToast("***deleted***")
        }
    }
};
</script>
