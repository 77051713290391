<template>
    <transition name="toast">
        <div v-if="show" class="toast-wrapper">
            <div class="toast">
                {{ text }}
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Toast",
    props: {
        text: { type: String, required: true },
        time: { type: Number, required: false, default: 2000 }
    },
    emits: ['timesUp'],
    computed: {
        show() {
            return Boolean(this.text);
        }
    },
    watch: {
        text() {
            if (this.show) {
                setTimeout(() => (this.$emit("timesUp")), this.time);
            }
        }
    }
};
</script>
<style>
.toast-wrapper {
    position: fixed;
    width: 100%;
    top: 20px;
}
.toast {
    padding: 20px;
    color: black;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    margin: 0 auto;
    opacity: 1;
}

/* enter transitions */
.toast-enter-active {
    animation: wobble 0.5s ease;
}
/* leave transitions */
.toast-leave-to {
    opacity: 0;
    transform: translateY(-60px);
}
.toast-leave-active {
    transition: all 0.3s ease;
}

@keyframes wobble {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }
    50% {
        transform: translateY(0px);
        opacity: 1;
    }
    60% {
        transform: translateX(8px);
        opacity: 1;
    }
    70% {
        transform: translateX(-8px);
        opacity: 1;
    }
    80% {
        transform: translateX(4px);
        opacity: 1;
    }
    90% {
        transform: translateX(-4px);
        opacity: 1;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}
</style>
