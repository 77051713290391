<template>
    <div class="columns is-mobile">
        <div
            :id="divId"
            ref="draggableContainer"
            :style="{ top: y + 'px', left: x + 'px' }"
            :class="selectorClass"
            @mousedown="dragMouseDown"
            @click="selectWord"
            @contextmenu="onContextMenu">
            {{ text }}
        </div>
    </div>
</template>

<script>
import { CallFirebaseFunction } from "../../functions/firebase";

export default {
    name: "Word",
    props: {
        id: { type: Number, required: true },
        text: { type: String, required: true },
        x: {
            type: Number,
            required: false,
            default() {
                return 1;
            }
        },
        y: {
            type: Number,
            required: false,
            default() {
                return 1;
            }
        },
        isSelected: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ["moved", "toggleSelected", "moving", "dragOthers", 'created', 'deleteMe', "analyzeSentiment", "analyzeSyntax"],
    data: function() {
        return {
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
                top: 0,
                left: 0,
                height: 0,
                width: 0
            },
            dragging: false
        };
    },
    computed: {
        selectorClass() {
            return this.isSelected
                ? "word badge badge-primary"
                : "word badge badge-success";
        },
        divId() {
            return `word_div_${this.id}`;
        }
    },
    mounted() {
        this.positions.height = this.$refs.draggableContainer.offsetHeight;
        this.positions.width = this.$refs.draggableContainer.offsetWidth;
        this.$emit("created", {id: this.id, height: this.positions.height, width: this.positions.width});
    },
    methods: {
        dragMouseDown: function(event) {
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag: function(event) {
            this.dragging = true;
            event.preventDefault();

            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;

            this.$emit("dragOthers", {
                x: this.positions.movementX,
                y: this.positions.movementY
            });

            this.top =
                this.$refs.draggableContainer.offsetTop -
                this.positions.movementY;
            this.left =
                this.$refs.draggableContainer.offsetLeft -
                this.positions.movementX;

            // set the element's new position:
            this.$refs.draggableContainer.style.top = this.top + "px";
            this.$refs.draggableContainer.style.left = this.left + "px";

            this.$emit("moving", this.id);
        },
        closeDragElement: function(event) {
            document.onmouseup = null;
            document.onmousemove = null;
            if (this.dragging) {
                this.selectWord();
                this.$emit("moved", this);
                this.dragging = false;
            }
        },
        selectWord: function(event) {
            this.$emit("toggleSelected", this);
        },
        onContextMenu(e) {
            //prevent the browser's default menu
            e.preventDefault();
            this.$contextmenu({
                x: e.x,
                y: e.y,
                items: [
                    { label: "delete", onClick: () => { this.$emit("deleteMe", this.id) }, },
                    { label: "Lookup", onClick: () => { this.lookupWord() }, },
                    { label: "Synonyms", onClick: () => { this.getSynonyms() }, },
                    {
                        label: "Analyze",
                        children: [
                            { label: "Analyze Syntax", onClick: () => {this.analyzeSyntax()} },
                            { label: "Analyze Sentiment", onClick: () => {this.analyzeSentiment()} },
                        ]
                    },
                ]
            });
        },
        async lookupWord() {
            await CallFirebaseFunction("LookupWord", this.text)
        },
        async getSynonyms() {
            await CallFirebaseFunction("GetSynonyms", this.text)
        },
        async analyzeSyntax() {
            // await this.callFirebaseFunction("analyzeSyntax")
            this.$emit("analyzeSyntax", this.id)
        },
        async analyzeSentiment() {
            // await this.callFirebaseFunction("analyzeSentiment")
            this.$emit("analyzeSentiment", this.id)
        }
    }
};
</script>

<style>
.word {
    position: absolute;
    cursor: pointer;
    padding: 8px 16px;
    box-shadow: 1px 1px rgb(153, 152, 152);
    /* font-family: "Noto Serif", serif; */
    font-size: 20px;
}
/*
.unselectedWord {
    color: red
}

.selectedWord {
    color: red;
} */
</style>
