const axios = require("axios");
const admin = require("./admin")

export default {
    namespaced: true,
    state: {
        words: [],
        themes: []
    },
    actions: {
        async loadCommonWords({ commit, state }) {
            const commonWords = await admin.GetCommonWords()
            commit("setWords", commonWords);
        },
        async loadOriginalKit({ commit, state }) {
            const words = await admin.GetOriginalKit()
            commit("setWords", words);
        },
        async loadThemes({ commit, state }) {
            axios
                .get("https://bootswatch.com/api/4.json")
                .then(res => {
                    commit("setThemes", res.data);
                })
                .catch(err => {
                    console.error(err);
                });
        }

    },
    mutations: {
        setWords(state, words) {
            state.words = words;
        },
        setThemes(state, { themes }) {
            state.themes = themes;
        }
    },
    getters: {
        themes(state) {
            return state.themes
        },
        distinctWords(state) {
            // dedupe and return only text
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            return state.words
                .map(word => word.word.toLowerCase())
                .filter(onlyUnique);
        },
        words(state) {
            return state.words;
        },
        commonNouns(state) {
            return state.words.filter(word => word.pos == "n");
        },
        commonVerbs(state) {
            return state.words.filter(word => word.pos == "v");
        },
        commonAdjectives(state) {
            return state.words.filter(word => word.pos == "a");
        },
        commonAdverbs(state) {
            return state.words.filter(word => word.pos == "r");
        },
        themeCdns(state) {
            return state.themes.reduce(function(map, obj) {
                map[obj.name] = obj.cssCdn;
                return map;
            });
        }
    }
};
