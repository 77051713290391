<template>
    <nav id="nav" class="navbar navbar-expand-lg navbar-light bg-light">
        <LoginButton />

        <li v-show="loggedIn && sessionIds.length > 0" class="nav-item mx-5">
            session:
            <select v-model="selectedSessionId" class="custom-select" @change="loadState" >
                <option v-for="session in sessions" :key="session">
                    {{ session.id }}
                </option>
            </select>
        </li>

        <li v-show="loggedIn && bootSwatchTheme.length > 0" class="nav-item">
            Bootswatch theme:
            <select
                v-model="bootSwatchTheme"
                class="custom-select"
                @change="changeTheme">
                <option v-for="themeName in themeNames" :key="themeName">
                    {{ themeName }}
                </option>
            </select>
        </li>

        <li v-show="loggedIn && sessionIds.length > 0" class="nav-item mx-5">
            title:
            <input id="title" v-model="localTitle" type="text" @change="setTitle"/>
        </li>
    </nav>
</template>

<script>
import { mapGetters } from "vuex";
import LoginButton from "./LoginButton";

export default {
    name: "NavBar",
    components: { LoginButton },
    props: {},
    data: function() {
        return { selectedSession: undefined, selectedSessionId: "", bootSwatchTheme: "", localTitle: "" };
    },
    computed: {
        ...mapGetters("user", ["avatar",
            "sessions",
            "sessionIds",
            "sessionId",
            "themeName",
            "loggedIn"]),
        ...mapGetters("common", ["themes"]),
        ...mapGetters("session", ["title"]),
        themeNames() {
            if (this.themes) return this.themes.map(t => t.name);
            else return [];
        },
        selectedTheme() {
            if (this.themes)
                return this.themes.find(t => t.name === this.bootSwatchTheme);
            else return undefined;
        }
    },

    watch: {
        themeName() {
            if (this.themeName) {
                this.bootSwatchTheme = this.themeName;
                this.changeTheme()
            }
        },
        sessionId() {
            this.selectedSession = this.sessions.find(s => s.id == this.sessionId)
            this.selectedSessionId = this.sessionId;

            if (this.sessionId) {
                this.loadState();
            }
        },
        title() {
            this.localTitle = this.title
        }
    },
    methods: {
        loadState() {
            this.$store.dispatch("session/loadState", this.selectedSessionId);
            this.$store.dispatch("user/setSession", this.selectedSessionId);
        },
        async changeTheme() {
            if (this.selectedTheme) {
                const links = Array.from(document.getElementsByTagName("link"));
                const cssLink = links.find(link => {
                    return link.rel == "stylesheet";
                });
                cssLink.href = this.selectedTheme.cssCdn
                this.$store.dispatch("user/setTheme", this.bootSwatchTheme)
            }
        },
        setTitle() {
            this.$store.commit("session/setTitle", this.localTitle);
        }
    }
};
</script>

<style scoped>
#profile-pic {
    display: inline-block;
    width: 75px;
    height: 75px;
    border-radius: 50%;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

nav li {
    list-style:none;
    background-image:none;
    background-repeat:none;
    background-position:0;
}

</style>
