const firebase = require("../../functions/firebase")
import { initializeApp } from "firebase/app"
import { getFirestore, collection, doc, getDoc, addDoc, setDoc, deleteDoc } from "firebase/firestore";

const app = initializeApp(firebase.firebaseConfig);
const db = getFirestore(app)

function getRandomPosition(canvasDimensions) {
    return {
        x: Math.floor(Math.random() * canvasDimensions.width),
        y: Math.floor(Math.random() * canvasDimensions.height)
    }
}

export default {
    namespaced: true,
    state: {
        sessionDocument: undefined,
        words: [],
        sessionId: null,
        title: null,
        canvasDimensions: { height: 100, width: 100 },
        randomSettings: { ceiling: 360, n: 50 }
    },
    actions: {
        async loadState({ commit, rootGetters, state }, sessionId) {
            const uid = rootGetters["user/uid"];

            if (uid !== undefined) {
                const userDocRef = rootGetters["user/userDocument"];
                const sessionsCollection = collection(userDocRef, "sessions")
                state.sessionDocument = doc(sessionsCollection, sessionId)
                const sessionSnapshot = await getDoc(state.sessionDocument)
                if (sessionSnapshot.exists) {
                    const sessionData = sessionSnapshot.data();
                    const {words, title} = sessionData;
                    commit("setWords", words);
                    commit("setTitle", title);
                    commit("setSessionId", sessionId);
                } else {
                    Promise.reject(`sessionId ${sessionId} not found `);
                }
            }
        },
        async saveState({ commit, state, getters }) {
            try {
                let sessionDoc;
                const payload = { words: state.words }
                if (state.title) payload.title = state.title

                if (state.sessionId === null) {
                    // no session yet, save a new one
                    state.sessionDocument = await addDoc(getters.sessionsCollection, payload);
                    state.sessionId = state.sessionDocument.id;
                } else {
                    // overwrite existing session
                    state.sessionDocument = doc(getters.sessionsCollection, state.sessionId);
                    await setDoc(state.sessionDocument, payload);
                }
                commit("setWords", state.words);
            } catch (err) {
                return Promise.reject(err.message);
            }
        },
        async deleteSession({commit, state, getters}) {
            try {
                await deleteDoc(state.sessionDocument)
                commit("deleteSession")
            } catch (error) {
                return Promise.reject(error.message)
            }
        },
        async newSession({ commit, state, getters }) {
            const newSessionDocument = await addDoc(getters.sessionsCollection, { words: [] });
            commit("setSessionId", newSessionDocument.id)
            commit("setWords", []);
            commit("setTitle", "");
            return state.sessionId;
        },
        saveWord({ commit, state }, word) {
            commit("setWord", word);
        },
        deleteWord({ commit, state }, wordId) {
            commit("deleteWord", wordId);
        },
        saveDimensions({ commit }, dimensions) {
            commit("saveDimensions", dimensions);
        },
        makeNewWord({ commit, state }, text) {
            const word = {
                id: state.words.length + 1,
                text: text,
                x: 1,
                y: 1
            };
            commit("addWord", word);
            return word;
        },
        clearWords({ commit, state }) {
            commit("clearWords");
        },
        shuffleWords({ commit, state }) {
            commit("shuffleWords");
        },
        adjustSpacing({ commit, state }, spacing) {
            commit("adjustSpacing", spacing);
        },
        setTitle({ commit, state }, title) {
            commit("setTitle", title);
        },
        getRandomWords({ commit, state, rootGetters }) {
            const n = state.randomSettings.n;
            const randomCeiling = state.randomSettings.ceiling;

            const distinctWords = rootGetters["common/distinctWords"];
            const startingPoint = Math.floor(Math.random() * randomCeiling + 1);

            // todo: validate distinctWords.length > randomCeiling + n
            let randomWords = distinctWords.slice(startingPoint, startingPoint + n);

            // give them some random starting point
            randomWords = randomWords.map((word, index) =>
                Object({
                    text: word,
                    id: index,
                    ...getRandomPosition(state.canvasDimensions)
                })
            );
            commit("setWords", randomWords);
        }
    },
    mutations: {
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        },
        deleteSession(state) {
            state.sessioinId = ""
            state.title = ""
            state.words = []
            state.sessionDocument = undefined
        },
        setTitle(state, title) {
            state.title = title;
        },
        clearWords(state) {
            state.words = [];
        },
        shuffleWords(state) {
            state.words.forEach(word => {
                const {x, y} = getRandomPosition(state.canvasDimensions)
                word.x = x
                word.y = y
            })
        },
        adjustSpacing(state, {oldValue, newValue}) {
            const multiplier = newValue / oldValue
            state.words.forEach(word => {
                word.x *= multiplier
                word.y *= multiplier
            })
        },
        setWords(state, words) {
            let i = 1;
            words.forEach(word => {
                word.id = i;
                i += 1;
            });
            state.words = words;
        },
        setWord(state, word) {
            state.words = state.words.filter(w => {
                return w.id !== word.id;
            });
            state.words.push(word);
        },
        deleteWord(state, wordId) {
            state.words = state.words.filter(w => {
                return w.id !== wordId;
            });
        },
        saveDimensions(state, dimensions) {
            state.canvasDimensions = dimensions;
        },
        addWord(state, word) {
            state.words.push(word);
        }
    },
    getters: {
        words(state) {
            return state.words;
        },
        title(state) {
            return state.title;
        },
        themeCdn(state, getters, rootState, rootGetters) {
            return rootGetters["common/themeCdns"][state.themeName];
        },
        sessionsCollection(state, getters, rootState, rootGetters) {
            const userDocRef = rootGetters["user/userDocument"]
            return collection(userDocRef, "sessions");
        },
    }
};
