const firebase = require("../../functions/firebase")
const { initializeApp } = require("firebase/app")
const { getFirestore, collection, doc, getDoc, getDocs, addDoc, setDoc, updateDoc, deleteField } = require("firebase/firestore")
const { getAuth, onAuthStateChanged } = require("firebase/auth")

const app = initializeApp(firebase.firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app);

export default {
    namespaced: true,
    state: {
        userDocRef: undefined,
        userSnapshot: undefined,
        user: {},
        sessions: [],
        sessionIds: [],
        sessionId: "",
        themeName: ""
    },

    actions: {
        async signOut({ commit, state }) {
            firebase.auth.signOut().then(() => {
                commit("setUser", {});
            });
        },
        async loadUserData({ commit, state }) {
            let sessions = [];
            let sessionIds = [];
            if (state.user && state.user.uid) {
                try {
                    const usersCollection = collection(db, "users")
                    state.userDocRef = doc(usersCollection, state.user.uid)
                    state.userSnapshot = await getDoc(state.userDocRef)
                    const userData = state.userSnapshot.data();
                    if (userData) {
                        // existing user
                        // get current theme and session
                        commit("setTheme", userData.theme)
                        commit("setSession", userData.sessionId)

                        // get sessions collection
                        const sessionDocs = collection(state.userDocRef, "sessions");
                        const sessionsSnapshot = await getDocs(sessionDocs);
                        sessionsSnapshot.forEach(sessionSnapshot => {
                            sessions.push({id: sessionSnapshot.id, ...sessionSnapshot.data()})
                            sessionIds.push(sessionSnapshot.id);
                        });
                    } else {
                        // new user, no document yet
                        await addDoc(state.userDocRef, { uid: state.user.uid });
                        const sessionsCollection = collection(state.userDocRef, "sessions")
                        const sessionDocRef = doc(sessionsCollection, "default")

                        await setDoc(sessionDocRef, {
                                words: [{ id: 1, text: "hello", x: 1, y: 1 },
                                    {
                                        id: 2,
                                        text: state.user.name,
                                        x: 75,
                                        y: 1
                                    }]
                            });
                    }
                } catch (err) {
                    console.error(err)
                    Promise.reject(err.message);
                }
            }
            commit("setSessionIds", sessionIds);
            commit("setSessions", sessions);
        },
        async setTheme({ commit, state }, themeName) {
            await updateDoc(state.userDocRef, {theme: themeName})
            commit("setTheme", themeName)
        },
        async setSession({ commit, state }, sessionId) {
            await updateDoc(state.userDocRef, {sessionId})
            commit("setSession", sessionId)
        },
        async deleteSession({ commit, state }) {
            await updateDoc(state.userDocRef, {"sessionId": deleteField()})
            commit("setSession", "")
        }
    },

    mutations: {
        setSessionIds(state, sessionIds) {
            state.sessionIds = sessionIds;
        },
        setSessions(state, sessions) {
            state.sessions = sessions;
        },
        addSession(state, session) {
            state.sessions.push(session);
            state.sessionIds.push(session.id);
        },
        setUser(state, user) {
            state.user = user;
        },
        setTheme(state, themeName) {
            state.themeName = themeName
        },
        deleteSession(state, sessionId) {
            commit("setSession", "")
            state.sessions = state.sessions.filter(s => s.id !== sessionId)
            state.sessionIds = state.sessionIds.filter(s => s !== sessionId)
        },
        setSession(state, sessionId) {
            state.sessionId = sessionId
            state.session = state.sessions.find(s => s.id == sessionId)
        }
    },

    getters: {
        loggedIn(state) {
            return state.user && state.user.uid ? true : false;
        },
        uid(state) {
            return state.user.uid;
        },
        avatar(state) {
            return state.user.avatar;
        },
        sessionIds(state) {
            return state.sessionIds;
        },
        sessions(state) {
            return state.sessions;
        },
        sessionId(state) {
            return state.sessionId;
        },
        themeName(state) {
            return state.themeName;
        },
        userDocument(state) {
            return state.userDocRef
        }
    }
};
